#firstRecord {
  display: none;
}

#secondRecord {
  display: none;
}

#finalRecord {
  display: node;
}

.Guide-link {
  color: black;
  text-decoration: none;
}