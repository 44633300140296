.Home {
  text-align: center;
  align-items: left;
}

.PastNewsConf {
  text-align: left;
}

#pastNews {
  display: none;
}